
/*---------------------------
CONTACT PAGE
-----------------------------*/

#contact-info{
    padding-top: 90px;
}
.contact-info-block{
    border: 1px solid $border-color;
    padding: 40px 20px;
    border-radius: 5px;

     h4{
        margin-bottom: 0px;
    }
    i{
        font-size: 40px;
        margin-bottom: 20px;
        display: block;
        color: $primary-color;
    }
}


.form-control:focus,.form-control:hover{
    border-color: $primary-color;
    box-shadow: none;
}


/* --------------
    MAP
-----------------*/

#map{
    height: 400px;
    width: 100%;
}