

/*----------------------
 PROCESS
-----------------------*/
.process-block {
    text-align: center;
     img {
	    border-radius: 5px;
	    margin-bottom: 30px;
	}
	h3 {
	    margin-bottom:15px;
	}

}
