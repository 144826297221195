
#banner-3{
    position: relative;
    background: url("../images/banner/banner.jpg")no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

#banner-4{
    position: relative;
    background: url("../images/banner/banner-2.jpg")no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

