@import "variables.scss";
@import "mixins.scss";
@import "media-query.scss";
@import "typography.scss";
@import "main.scss";
@import "common.scss";
@import "templates/header.scss";
@import "templates/page-banner.scss";
@import "templates/navigation.scss";
@import "templates/slider.scss";
@import "templates/backgrounds.scss";
@import "templates/about.scss";
@import "templates/service.scss";
@import "templates/portfolio.scss";
@import "templates/process.scss";
@import "templates/pricing.scss";
@import "templates/blog.scss";
@import "templates/testimonial.scss";
@import "templates/contact.scss";
@import "templates/footer.scss";
@import "templates/consent.scss";
@import "responsive.scss";



#header .logo img {
    max-height: 40px;
}