$light: #fff;
// $primary-color: #2674A4;
$primary-color: #095585;
$secondary-color: #F4F7FC;
$black: #212529;
$black-light: #6c757d;
$black-lighter: #888;
$border-color:#eee;
$primary-font: 'Muli', sans-serif;
$secondary-font:'Muli', sans-serif;
