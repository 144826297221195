/*----------------------
 HEADER STYLE
-----------------------*/

.header-socials {
  line-height: 22px;
  margin-bottom: 0px;

  li {
    padding: 8px;

    a {
      color: #2674A4;
    }
  }
}


/*-----------------
 LOGO BAR
-------------------*/

.logo-bar {
  padding: 30px 0px 60px;
}

.top-info-block {
  margin-right: 30px;

  h5,
  p {
    margin-bottom: 0px;
  }
}


.icon-block {
  margin-right: 20px;
  color: #2674A4;
  font-size: 40px;
  line-height: 20px;
  font-weight: 400;
}


.top-info-block:last-child {
  margin-right: 0px;
}

.header-bar {
  overflow: visible;
  position: relative;
  padding-bottom: 30px;
}


.main-navigation {
  margin-top: -40px;
}




/*-----------------------
 HOME-3
---------------------------*/

.header-white {
  background: $primary-color;
  -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
  border-radius: 0px;
}

.trans-navigation {
  @include desktop {
    background: $primary-color;
    -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    border-radius: 0px;
  }
}

.trans-navigation .navbar-brand h4,
.site-navigation .navbar-brand h4,
.site-navigation .navbar-brand h3,
.trans-navigation .navbar-brand h3 {
  color: $primary-color;
}

.banner-content.style-2 {
  padding-top: 200px;
}

.trans-navigation .navbar-brand {
  padding-top: 13px;
}



.site-navigation.header-white {
  border-bottom: 1px solid transparent;
}

.site-navigation .navbar-brand {
  padding-top: 13px;
  margin-right: 20px;
}


.banner-content2 {
  padding: 200px 0px;

  .display-4 {
    color: $light;
    font-weight: 600;
  }

  p.lead {
    color: $light;
  }
}






/* =========================
PRELOADER
=======================*/

#page-loader {
  background: $light;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1050;
}

.loader-icon {
  background: none repeat scroll 0 0 $light;
  border-bottom: 3px solid rgba(19, 19, 19, 0.1) !important;
  border-left: 3px solid rgba(19, 19, 19, 0.1) !important;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  -ms-border-radius: 100px;
  border-right: 3px solid #1453F2 !important;
  border-top: 3px solid rgba(19, 19, 19, 0.1) !important;
  height: 50px;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.colored-border {
  border-color: $light;
}

.fa-spin {
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
