/*----------------------
 SERVICE
-----------------------*/
h5.subtitle {
  color: $primary-color;
  margin-bottom: 10px;
  font-weight: 300;
}

.service-heading {
  margin-bottom: 50px;
}

.service-images {
  margin-bottom: -200px;
  border: 10px solid $light;
  z-index: 9999;
}

.service-content {
  padding-top: 60px;
  padding-left: 15px;

  h1 {
    margin-bottom: 20px;
    margin-top: 15px;
  }
}

.service-icon {
  margin-right: 20px;
  color: $light;
  font-size: 25px;
  width: 50px;
  height: 50px;
  background: $primary-color;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
}

.service-block {
  margin-bottom: 50px;
}


.service-inner-content h4 {
  text-transform: uppercase;
}


/*----------------------
 WEB SERVICES
-----------------------*/
#services-2 {
  position: relative;
  background: url("../images/zused/Banner-3.webp")no-repeat fixed 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;

  &:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(30deg, rgba(121, 110, 255, 0.95) 0%, rgba(33, 200, 122, 0.95) 100%);
    background-image: linear-gradient(30deg,rgba(73, 59, 59, 0.559) 0%, rgb(0, 0, 0) 100%);
  }
}


.web-service-block {
  text-align: center;
  padding: 35px 25px;
  transition: .3s;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;

  i {
    font-size: 70px;
    margin-bottom: 30px;
    display: block;
    color: $light;
  }

  h3 {
    color: $light;
    transition: 0.3s;
    font-size: 30px;

  }

  p {
    color: $border-color;
    margin-top: 15px;
    transition: 0.3s;
    font-size: 16px;
  }

  &:hover {
    background: $light;
    border-color: transparent;

    i {
      color: $primary-color;
    }

    h3 {
      color: #111;
    }

    p {
      color: #111;
    }
    a{
      color: $light;
      background: $primary-color;
    }
  }
}

.service-img {
  margin-bottom: -200px;
  border: 10px solid $light;
  z-index: 9999;

}